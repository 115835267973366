import React from "react"
import { graphql } from "gatsby"
import { Container, Stack, Grid } from "@chakra-ui/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceCard from "../components/partials/service-card"
import { Pagination } from "../components/partials/pagination"
import SubpageParallaxImageHero from "../components/partials/subpage-parallax-image-hero"

const Services: React.FC<any> = ({ data }) => {
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } =
    data.allContentfulServices.pageInfo

  return (
    <Layout>
      <Seo
        title="サービス一覧"
        description="サービス一覧"
        image={data.allContentfulSiteImages.edges[0].node.image.url}
      />
      {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
        <SubpageParallaxImageHero
          url={siteImage.image.url}
          caption={`サービス一覧`}
          fontFamily="sans"
        />
      ))}
      <Container maxW="container.xl" as={`main`} p={0}>
        {data && (
          <Stack pb={{ base: 8, lg: 24 }} pt={{ base: 8, lg: 24 }} spacing={8}>
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
              gap={6}
            >
              {data.allContentfulServices.edges.map(
                ({ node: service }: any) => {
                  return <ServiceCard service={service} imageHeight={64} />
                }
              )}
            </Grid>
            <Pagination
              baseUrl={`/services`}
              currentPage={currentPage}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              pageCount={pageCount}
            />
          </Stack>
        )}
      </Container>
    </Layout>
  )
}

export default Services

export const blogListQuery = graphql`
  query GetServices($skip: Int!, $limit: Int!) {
    allContentfulSiteImages(filter: { imageNo: { eq: 5 } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulServices(
      limit: $limit
      filter: { published: { eq: true } }
      sort: { order: DESC, fields: createdAt }
      skip: $skip
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        totalCount
        pageCount
      }
      edges {
        node {
          name
          slug
          id
          description {
            description
          }
          featuredPhoto {
            file {
              url
            }
          }
        }
      }
    }
  }
`
