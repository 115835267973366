import React from "react"
import { Link as GL } from "gatsby"

import {
  Heading,
  Stack,
  Text,
  Box,
  GridItem,
  Image,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"

interface Props {
  service: any
  imageHeight?: string | number
}

export const ServiceCard: React.FC<any> = ({ service, imageHeight }) => {
  return (
    <GridItem as={`article`} w="100%">
      <LinkBox>
        <Stack spacing={0}>
          {service.featuredPhoto && (
            <Image
              src={service.featuredPhoto.file.url}
              h={imageHeight || 48}
              objectFit={`cover`}
            />
          )}
          <Box py={4} px={4} pb={4} bg={`white`} color={`#212720`}>
            <LinkOverlay as={GL} to={`/services/${service.slug}`}>
              <Heading fontSize={15} lineHeight={1.8}>
                {service.name}
              </Heading>
            </LinkOverlay>

            <Text mt={2} noOfLines={2} fontSize={13}>
              {service.description.description}
            </Text>
          </Box>
        </Stack>
      </LinkBox>
    </GridItem>
  )
}

export default ServiceCard
